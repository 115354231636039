import React, {Component} from 'react';
import Restcall from './services/Restcall'

import { trackPromise } from 'react-promise-tracker'

import Shop from './Componens/Shop'
import InOut from './Componens/InOut'
import EmployeeList from './Componens/EmployeeList'
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
       return (
        promiseInProgress &&
        <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div>
            <Loader type="ThreeDots" color="#2BAD60" height="300" width="300" />
        </div>
        
        </div>
      );  
   }


class Body extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop_list: [],
            employee_list: [],
            current_shop: null,
            in_or_out: null,
            isWaiting: false
        }
    }

    goBackToShop = () => {
        this.setState({ current_shop: null})
    }
    
    goBackToInOut = () => {
        this.setState({ in_or_out: null})
    }


    retrieveShopList = () => {
        trackPromise(
        Restcall.shopRetrieveEP().then(response => response.data)
        .then((data) => {
            console.log(data)
            this.setState({ shop_list: data.content})
           })
        )
    }

    getMethod = (m) => {
        this.setState({in_or_out: m})
    }

    getShop = (shop_name) => {
        this.retrieveEmplyeeList(shop_name)
        this.setState({current_shop: shop_name})
    }

    retrieveEmplyeeList = (location) => {
        this.setState({isWaiting: true})
        trackPromise(
        Restcall.employeeRetrieveEP(location).then(response => response.data)
        .then((data) => {
            console.log(data)
            this.setState({ employee_list: data.content, isWaiting: false})
           })
        )
    }

    changeWorkingStatus = (id) => {
        const {employee_list} = this.state
        for (let i = 0; i < employee_list.length; i++) {
            console.log(employee_list[i].id)
            if (employee_list[i].id === id){
                employee_list[i].is_working = !employee_list[i].is_working
            }
        } 
    }

    componentDidMount() {
        if(this.props.current_shop) {
            this.getShop(this.props.current_shop)
            this.retrieveEmplyeeList(this.props.current_shop)
        }
        else {
            this.retrieveShopList()
        }
    }


    render() {

        const {shop_list, current_shop, in_or_out, employee_list, isWaiting} = this.state
        let shop_array =  []
        Object.keys(shop_list).forEach(key => shop_array.push(shop_list[key]))

        return (
            <div>
                {!(current_shop !== null) && (<div>{shop_array.map((shop, i) => {
                        return (<Shop shop={shop} key={i} getShop={this.getShop}/>)
                    })}
                </div>)}
                {((current_shop !== null) && (in_or_out === null && !isWaiting)) && (
                    <InOut current_shop={current_shop} getMethod={this.getMethod} goBackToShop={this.goBackToShop}/>
                )}
                {((current_shop !== null) && (in_or_out !== null)) && (
                    <EmployeeList current_shop={current_shop} method={in_or_out} goBackToInOut={this.goBackToInOut} employee_list={employee_list} changeWorkingStatus={this.changeWorkingStatus}/>
                )}
                {isWaiting && <LoadingIndicator/>}
            </div>

        );
    }
}

export default Body