import React, {Component} from 'react';
import Restcall from '../services/Restcall'
import { trackPromise } from 'react-promise-tracker'
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';

const LoadingIndicator2 = props => {
    const { promiseInProgress } = usePromiseTracker();
       return (
        promiseInProgress &&
        <div
        style={{
          width: "100%",
          height: "70",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader type="ThreeDots" color="#2BAD60" height="70" width="100" />
      </div>
      );  
   }

class Pin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pin: '',
            message:'',
            isWaiting: false
        }
    }

    doClock = (method, employee) => {
        const {pin} = this.state
        if (pin.length < 1 || pin.length > 8) {
            this.setState({message: 'Wrong PIN', pin: ''})
        }
        else {
            this.setState({isWaiting: true})
            trackPromise(
            Restcall.doClock(method, employee, pin, 10).then(response => response.data)
            .then((data) => {
                console.log(data)
                if (data.content === 'OK') {
                    const {goBackToInOut, changeWorkingStatus} = this.props
                    console.log(employee['id'])
                    changeWorkingStatus(employee['id'])
                    goBackToInOut()
                }
                else {
                    this.setState({message: data.content, pin: '', isWaiting: false})
                }
               })
            )
        }

    }

    handleSubmit = e => {
        e.preventDefault();
        const {employee, method} = this.props
        this.doClock(method, employee)
    }


    updateField = ({ target }) => {
        const { name, value } = target
        this.setState({[name]: value, message: ''})
    }


    render() {

        const {message, isWaiting} = this.state
        
        return (
            <div>
                {message !== '' && (
                    <div className='error-title heavy'>
                        {message}
                    </div>
                )}
                {!isWaiting && (
                    <form className='full-width farside neartop nearbottom' onSubmit={this.handleSubmit}>
                        <input autoFocus
                                className="f-input font-big"
                                type="password"
                                pattern="[0-9]*" 
                                inputmode="numeric"
                                name="pin"
                                placeholder="PIN..." 
                                value={this.state.pin}
                                onChange={this.updateField}
                        ></input>
                        <button className='farside font-big' disabled={isWaiting}> ENTER</button>
                    </form>
                )}
                {isWaiting && <LoadingIndicator2/>}


            </div>
        );
    }
}


export default Pin