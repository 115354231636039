import React, {Component} from 'react';
import ShopPin from './ShopPin'

class Shop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenPin: false
        }
    }

    openPin = e => {
        e.preventDefault();
        this.setState({isOpenPin: !this.state.isOpenPin})
    }

    takeShop = (shop) => {
        const {getShop} = this.props
        getShop(shop)
    }



    render() {

        const {shop} = this.props


        return(
            <div>
                {!this.state.isOpenPin && (
                    <div className='content-box div-onClick ' onClick={this.openPin}>
                        <div className='text-center blue heavier fartop font-big '>
                            {shop['shop_name']}
                        </div>
                    </div>
                )}
                {this.state.isOpenPin && (
                    <div>
                        <div className='content-box div-onClick' onClick={this.openPin}>
                            <div className='text-center blue heavier fartop font-big '>
                                {shop['shop_name']}
                            </div>
                        </div>
                        <ShopPin shop={shop} takeShop={this.takeShop} />
                    </div>
                )}
            </div>  
        );
        

    }

}

export default Shop