import React, {Component} from 'react';


class InOut extends Component {


    handleGetMethodIn = e => {
        e.preventDefault();
        const {getMethod} = this.props
        getMethod('IN')
    }

    handleGetMethodOut = e => {
        e.preventDefault();
        const {getMethod} = this.props
        getMethod('OUT')
    }

    goBack = (e) => {
        e.preventDefault();
        const {goBackToShop} = this.props
        goBackToShop()
    }

    componentDidMount() {
    }


    render() {

        const {current_shop} = this.props

        return (
            <div >
                <div className='cookingbook-title font-big'>
                    <p className='cookingbook-title'>{current_shop}</p>
                </div>
                <div className='content-box div-onClick' onClick={this.handleGetMethodIn}>
                    <div className='text-center green font-huge heavier'>
                        <p className='neartop'>IN</p>
                    </div>
                </div>
                <div className='content-box div-onClick ' onClick={this.handleGetMethodOut}>
                    <div className='text-center red font-huge heavier'>
                        <p className='neartop'>OUT</p>
                    </div>
                </div>
            </div>

        );
    }
}


export default InOut