import React, {Component} from 'react';
import Restcall from '../services/Restcall'
import { trackPromise } from 'react-promise-tracker'

class ShopPin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pin: '',
            message:'',
            isWaiting: false
        }
    }

    openShop = () => {
        const {shop} = this.props
        const {pin} = this.state
        if (pin.length !== 8) {
            this.setState({message: 'Wrong PassCode', pin: ''})
        }
        else {
            this.setState({isWaiting: true})
            Restcall.openShop(shop['shop_name'], pin).then(response => response.data)
            .then((data) => {
                console.log(data)
                if (data.content === 'OK') {
                    const {takeShop} = this.props
                    takeShop(shop['shop_name'])
                }
                else {
                    this.setState({message: data.content, pin: '', isWaiting: false})
                }
               })
        }

    }


    handleSubmit = e => {
        e.preventDefault();
        this.openShop()
    }


    updateField = ({ target }) => {
        const { name, value } = target
        this.setState({[name]: value, message: ''})
    }


    render() {

        const {message, isWaiting} = this.state
        
        return (
            <div>
                {message !== '' && (
                    <div className='error-title heavy'>
                        {message}
                    </div>
                )}
                <form className='full-width farside ' onSubmit={this.handleSubmit}>
                <input autoFocus
                        className="f-input small font-big"
                        type="password"
                        name="pin"
                        placeholder="PassCode..." 
                        value={this.state.pin}
                        onChange={this.updateField}
                    ></input>
                <button className='farside font-big' disabled={isWaiting}> ENTER</button>
                </form>
            </div>
        );
    }
}


export default ShopPin