import Axios from "axios";
import { baseUrl} from "../Config.json";

const HTTPMethod = Object.freeze({
    GET: "GET",
    POST: "POST",
    DELETE: "DELETE"
  });

function initSocket() {
    Axios.defaults.baseURL = baseUrl;
}

async function shopRetrieveEP() {
    let path = 'exec?content=shop'
    return await sendHTTP(HTTPMethod.GET, path);
};

async function employeeRetrieveEP(location) {
  let path = 'exec?content=employee'
  path += '&shop=' + location
  return await sendHTTP(HTTPMethod.GET, path);
}

async function doClock(method, employee, pin) {
  let path = 'exec'
  const payLoad = new FormData();
  payLoad.append('content', 'doclock')
  payLoad.append('id', employee['id'])
  payLoad.append('first_name', employee['first_name'])
  payLoad.append('last_name', employee['last_name'])
  payLoad.append('shop', employee['shop'])
  payLoad.append('method', method)
  payLoad.append('pin', pin)
  return await sendHTTP(HTTPMethod.POST, path, payLoad);
}

async function openShop(shop, pin) {
  let path = 'exec'
  const payLoad = new FormData();
  payLoad.append('content', 'openshop')
  payLoad.append('shop', shop)
  payLoad.append('pin', pin)
  return await sendHTTP(HTTPMethod.POST, path, payLoad);
}

async function sendHTTP(method, path, data) {
    let response;
  
    switch (method) {
      case HTTPMethod.GET:
        response = await Axios.get(path);
        break;
      case HTTPMethod.POST:
        response = await Axios.post(path, data, {headers: {
          'Content-type': 'multipart/form-data'
        }});
        break;
      case HTTPMethod.DELETE:
        break;
      default:
      // Should never reach here

    }
    return await response;
}

let exportObject = {initSocket, shopRetrieveEP, employeeRetrieveEP, doClock, openShop};

export default exportObject

