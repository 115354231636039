import React, {Component} from 'react';
import Restcall from '../services/Restcall'
import { trackPromise } from 'react-promise-tracker'


import Employee from './Employee'
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
       return (
        promiseInProgress &&
        <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader type="ThreeDots" color="#2BAD60" height="300" width="300" />
      </div>
      );  
   }


class EmployeeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            in_employee_list: [],
            activeEmp: 0
        }
    }

    goBack = e => {
        e.preventDefault();
        const {goBackToInOut} = this.props
        goBackToInOut()

    }
    
    changeActive = (key) => {
        this.setState({activeEmp: key})
    }

    getTheEmployeeList = () => {
        const {employee_list, method} = this.props
        let active_emp_list = []
        let method_status = true
        if (method === 'OUT') {
            method_status = false
        }
        for (let i = 0; i < employee_list.length; i++) {
            if (employee_list[i].is_working !== method_status)
            active_emp_list.push(employee_list[i])
        } 
        this.setState({in_employee_list: active_emp_list})
    }

    componentDidMount() {
        this.getTheEmployeeList()
    }

    render() {
        const {in_employee_list} = this.state
        const {method, goBackToInOut, changeWorkingStatus} = this.props
        let employee_array =  []
        Object.keys(in_employee_list).forEach(key => employee_array.push(in_employee_list[key]))

        return (
            <div>
                <div className='cookingbook-title font-big'>
                    <div className='back-title-button div-onClick' onClick={this.goBack}>
                        <div className='center'>
                            <i className="fa fa-angle-left fa-3x" aria-hidden="true"></i>
                        </div>
                    </div>
                    {(method==='IN') && (<p className='cookingbook-title'>CLOCK <span className='green'>{method}</span></p>)}
                    {(method==='OUT') && (<p className='cookingbook-title'>CLOCK <span className='red'>{method}</span></p>)}
                </div>
                <div>{employee_array.map((employee, i) => {
                    return (<Employee employee={employee} key={i} goBackToInOut={goBackToInOut} method={method} changeActive={this.changeActive} activeEmp={this.state.activeEmp} changeWorkingStatus={changeWorkingStatus}/>)
                    })}
                </div>
                {(in_employee_list.length === 0) && <LoadingIndicator/>}

            </div>
        );
    }

}


export default EmployeeList