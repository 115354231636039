import React, {Component} from 'react';
import Pin from './Pin'

class Employee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPin: false
        }
    }

    closePopup = () => {
        this.setState({openPin: false})
    }


    handleClock = e =>{
        e.preventDefault();
        const {employee, changeActive} =  this.props
        changeActive(employee['id'])
        this.setState({openPin: !this.state.openPin})
    }

    componentDidUpdate (prevProps) {
        const {employee} =  this.props
        if(this.props.activeEmp !== prevProps.activeEmp) {
            if (employee['id'] !== this.props.activeEmp) {
                this.closePopup()
            }
        }
    } 


    render () {

        const {employee, method, goBackToInOut, changeWorkingStatus} = this.props

        return (
            <div>
                {!this.state.openPin && (
                    <div className='ingredient-label' onClick={this.handleClock}>
                        <div >
                            {employee['first_name']} {employee['last_name']}
                        </div>
                    </div>
                )}
                {this.state.openPin && (
                    <div>
                        <div className='ingredient-label-select' onClick={this.handleClock}>
                            <div >
                                {employee['first_name']} {employee['last_name']}
                            </div>
                        </div>
                        <Pin employee={employee} method={method} goBackToInOut={goBackToInOut} changeWorkingStatus={changeWorkingStatus}/>
                    </div>
                )}

            </div>


        );
    }


}


export default Employee