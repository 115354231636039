import React, {Component} from 'react';

class Header extends Component {



    render() {
        return (
            <div className='full-width neartop nearbottom closeside'>
                <div className='heavy red font-huge'>
                    <div> BMC Pho TimeClock</div>
                </div>
            </div>
        );
    }
}

export default Header