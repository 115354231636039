import './App.css';
import Header from './Header';
import Body from './Body'
import { Route, Routes } from "react-router-dom";


import './css/style.css'


function App() {
  return (
    <div>
      <Header />
          <Routes>
            <Route path="/El-Monte-Lower-Azusa" element={<Body current_shop='El Monte Lower Azusa'/>} />
            <Route path="/El-Monte-Valley" element={<Body current_shop='El Monte Valley'/>} />  
            <Route path="/Hacienda-Heights" element={<Body current_shop='Hacienda Heights'/>} />  
            <Route path="/Pico-Rivera" element={<Body current_shop='Pico Rivera'/>} />  
            <Route path="/Monterey-Park" element={<Body current_shop='Monterey Park'/>} />  
            <Route path="/West-Covina" element={<Body current_shop='West Covina'/>} />  
            <Route path="/Covina" element={<Body current_shop='Covina'/>} />  
            <Route exact path="/" element={<Body/>} />
          </Routes>
      
    </div>

  );
}

export default App;
